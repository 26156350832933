import React, { useEffect, useState, useRef } from 'react';
import { getPopularProducts, getProducts, getTodayProducts } from '../services/product';
import ProductCard from '../components/ProductCard';
import CategoryCard from '../components/CategoryCard';
import Carousel from '../components/Carousel';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { getCategories } from '../services/category';
import { getShopAdvs, getSlides } from '../services/slides';
import PartnerCard from '../components/PartnerCard';
import SupplierCard from '../components/SupplierCard';
import safeIcon from "../assets/safe.webp";
import expert from "../assets/expert.png";
import best from "../assets/best.png";
import CustomRowColumnCard from '../components/CustomRowColumnCard';
import adv1 from "../assets/adv (1).jpg";
import adv2 from "../assets/adv (2).jpg";
import adv3 from "../assets/adv (3).jpg";
import { getBrands, getSellers } from '../services/manufacturer';
import { useTranslation } from 'react-i18next';
import { callPagination } from '../services/pagination';
import { getCurrentPosition } from "geolocation";
import LoadingSpinner from '../components/LoadingSpinner';


const Home = () => {
    const { t } = useTranslation();
    const isTab = useMediaQuery('(max-width: 966px)');
    const is1230 = useMediaQuery('(max-width: 1230px)');

    const [todayProducts, setTodayProducts] = useState([]);
    const [topProducts, setTopProducts] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [bestProducts, setBestProducts] = useState([]);
    const [carouselImages, setCarouselImages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [partners, setPartners] = useState([]);
    const [nearbySellers, setNearbySellers] = useState([]);
    const [shopAdv, setShopAdv] = useState([]);
    const listInnerRef = useRef();
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        setShowLoader(true);
        const fetchRequired = async () => {

            const slides = await getSlides();
            setCarouselImages(slides.data);

            setShowLoader(false);

            const prods = await getTodayProducts();
            setTodayProducts(prods.data.results);

            const categs = await getCategories();
            setCategories(categs.data);

            const topProds = await getProducts({ filter: "order=0" });
            setTopProducts(topProds.data.results);

            const bestProds = await getProducts({ filter: "order=1" });
            setBestProducts(bestProds.data.results);

            const popProds = await getPopularProducts();
            setPopularProducts(popProds.data.results);
            setNextPage(popProds.data.next);

            const shopAd = await getShopAdvs();
            setShopAdv(shopAd.data);

            const brands = await getBrands();
            setPartners(brands.data.results);
            getCurrentPosition(async function (err, position) {
                if (err) {
                    console.log("Error in fetching location", err);
                    const sellers = await getSellers("", "");
                    setNearbySellers(sellers.data.results);
                } else {
                    const sellers = await getSellers(position.coords.latitude, position.coords.longitude);
                    setNearbySellers(sellers.data.results);
                }
            });
        };
        fetchRequired();
    }, []);

    // Infinite scrolling logic
    useEffect(() => {
        if (!listInnerRef.current) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && nextPage) {
                    callMorePages();
                }
            },
            { threshold: 1 }
        );

        observer.observe(listInnerRef.current);

        return () => observer.disconnect();
    }, [nextPage]);

    const callMorePages = async () => {
        if (!nextPage || loading) return; // Prevent multiple calls if no next page or already loading

        setLoading(true); // Set loading to true before making the API call
        try {
            const nextPageData = await callPagination(nextPage);
            setPopularProducts((prevProducts) => [...prevProducts, ...nextPageData.data.results]);
            setNextPage(nextPageData.data.next); // Update the next page URL
        } catch (error) {
            console.error("Error fetching more pages: ", error);
        } finally {
            setLoading(false); // Reset loading state after API call completes
        }
    };

    // Infinite scrolling logic with throttling
    const handleScroll = () => {
        if (loading || !nextPage) return;

        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollY + windowHeight >= documentHeight - 100) {
            callMorePages();
        }
    };

    useEffect(() => {
        let timeout;
        const handleThrottledScroll = () => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(handleScroll, 200);
        };

        window.addEventListener('scroll', handleThrottledScroll);
        return () => {
            window.removeEventListener('scroll', handleThrottledScroll);
            clearTimeout(timeout);
        };
    }, [nextPage, loading]);

    return (
        <div className="mt-32 mb-10">
            <Carousel images={carouselImages} />
            <h1 className='text-center text-xl md:text-3xl my-4 font-bold'>{t("categories")}</h1>
            <div className="px-4 md:px-14">
                <hr className='bg-secondary h-[1px] w-full border-0' />
            </div>

            {/* Limit the categories to 5 items and add a View All card as the 6th */}
            <div className={`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-9 gap-4 md:gap-8 ${isTab ? "py-10 px-4" : "px-14 pt-10"}`}>
                {categories.slice(0, 8).map((category) => (
                    <CategoryCard key={category.id} category={category} />
                ))}

                {/* The 6th card with View All */}
                <Link to="/categories">
                    <div
                        key="View all button"
                        className="flex flex-col items-center cursor-pointer justify-center w-full h-full"
                    >
                        <div className="relative m-4 aspect-square flex-1">
                            <div className="rounded-full overflow-hidden border-2 border-primary w-full h-full flex items-center text-center justify-center bg-orange-100">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 md:h-10 md:w-10 text-primary group-hover:text-secondary transition-all"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </div>
                        </div>
                        <span className="text-sm md:text-[1rem] text-center font-semibold flex-1">View All</span>
                    </div>
                </Link>
            </div>

            {todayProducts.length !== 0 ?
                (<div className="flex flex-row justify-between px-4 md:px-14 items-center">
                    <p className='text-center text-2xl my-4 font-bold'>{t("todayOffer")}</p>
                    {/* <Link to="/view/products?utm=Today's Offer" className="text-primary hover:text-gray-900 underline decoration-primary">View All</Link> */}
                </div>) : <></>}
            <div className="flex overflow-x-auto space-x-4 mb-4 mx-2 md:mx-12 pb-4 px-2">
                {todayProducts.map((product) => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </div>

            {topProducts.length !== 0 ? (
                <div className="flex flex-row justify-between px-4 md:px-14 items-center">
                    <p className='text-center text-2xl my-4 font-bold'>{t("topOffer")}</p>
                    {/* <Link to="/view/products?utm=Top Offers" className="text-primary hover:text-gray-900 underline decoration-primary">View All</Link> */}
                </div>) : (<></>)}
            <div className="flex overflow-x-auto space-x-4 mb-4 mx-4 md:mx-14 pb-4">
                {topProducts.map((product) => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </div>
            <div className={`flex overflow-x-auto px-4 py-4 items-center gap-4 text-center w-full bg-primary ${is1230 ? "justify-start" : "justify-center"}`}>
                <Link to={"/view/products"} target='_blank' className="h-[5.5rem] md:h-[7rem] flex-shrink-0">
                    <img src={adv1} alt="adv image 1" className="h-[5.5rem] md:h-[7rem] object-contain flex-shrink-0" />
                </Link>
                <Link to={"https://preprod.dashboard.rashailagro.in/fasalam-super-app/qr"} target='_blank' className="h-[5.5rem] md:h-[7rem] flex-shrink-0">
                    <img src={adv2} alt="adv image 2" className="h-[5.5rem] md:h-[7rem] object-contain" />
                </Link>
                <Link to={"/view/products"} target='_blank' className="h-[5.5rem] md:h-[7rem] flex-shrink-0">
                    <img src={adv3} alt="adv image 3" className="h-[5.5rem] md:h-[7rem] object-contain flex-shrink-0" />
                </Link>
            </div>
            <div className="flex flex-row justify-between px-4 md:px-14 items-center">
                <p className='text-center text-2xl my-4 font-bold'>{t("ourBrands")}</p>
                {/* <Link to="/shop/partners" className="text-primary hover:text-gray-900 underline decoration-primary">View All</Link> */}
            </div>
            <div className="flex overflow-x-auto space-x-4 mb-4 mx-4 md:mx-14 pb-4">
                {partners.map((partner) => (
                    <PartnerCard key={partner.id} partner={partner} />
                ))}
            </div>
            <div className={`grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-1.5 md:gap-4 px-2 md:px-14 py-2 bg-white`}>
                {shopAdv.map((adv) => (
                    <Link to={adv.link} target='_blank' className='border-2 rounded-lg shadow-lg'><img src={adv.image} className='rounded-lg' alt={adv.image} /></Link>
                ))}
            </div>
            {bestProducts.length !== 0 ?
                (<div className="flex flex-row justify-between px-4 md:px-14 items-center">
                    <p className='text-center text-2xl my-4 font-bold'>{t("bestSellers")}</p>
                    {/* <Link to="/view/products?utm=Top Offers" className="text-primary hover:text-gray-900 underline decoration-primary">View All</Link> */}
                </div>) : (<></>)}
            <div className="flex overflow-x-auto space-x-4 mb-4 mx-2 md:mx-12 px-2 pb-4">
                {bestProducts.map((product) => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </div>
            <div className="flex overflow-x-auto gap-2 flex-row justify-evenly items-center bg-green-300 py-5">
                <CustomRowColumnCard image={safeIcon} text={<>{t("safeSecure")} <br /> {t("payments")}</>} />
                <CustomRowColumnCard image={expert} text={t("expertAdvice")} />
                <CustomRowColumnCard image={best} text={<>{t("bestPrice")} <br /> {t("assured")}</>} />
            </div>
            {
                nearbySellers.length > 0 ?
                    <div className="flex flex-row justify-between px-4 md:px-14 items-center">
                        <p className='text-center text-2xl my-4 font-bold'>{t("nearbySuppliers")}</p>
                        {/* <Link to="/shop/near-by-suppliers" className="text-primary hover:text-gray-900 underline decoration-primary">View All</Link> */}
                    </div> : <></>
            }
            <div className="flex overflow-x-auto space-x-4 mb-4 mx-4 md:mx-14 pb-4">
                {nearbySellers.map((partner) => (
                    <SupplierCard key={partner.id} partner={partner} />
                ))}
            </div>
            {popularProducts.length !== 0 ?
                (<div className="flex flex-row justify-between px-4 md:px-14 items-center">
                    <p className='text-center text-2xl my-4 font-bold'>{t("popularProducts")}</p>
                </div>) : (<></>)}
            {
                popularProducts.length !== 0 ?
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 px-2 md:px-24">
                        {popularProducts.map((product) => (
                            <ProductCard key={product.id} product={product} />
                        ))}
                        {/* Invisible div to observe for triggering pagination */}
                        <div ref={listInnerRef} className="w-full h-1"></div>
                    </div> : <></>
            }
            {loading ? <div className='w-full items-center justify-center'><LoadingSpinner /></div> : <></>}
            {showLoader ? <div className='w-full h-full bg-black bg-opacity-40 z-50 fixed top-0 left-0'><LoadingSpinner /></div> : <></>}
        </div>
    );
};

export default Home;
