import React, { useState } from 'react';

const CustomLoadingRectangularImage = ({
    imageUrl,
    height,
    width,
    borderRadius = 25,
    isUserImage = false,
    needDifferentBorderRadius = false,
    needFitCover = true,
    borderRadiusCustom,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const placeholderImage = 'https://via.placeholder.com/150'; // Replace with your loading image URL
    const errorImage = 'https://via.placeholder.com/150'; // Replace with your app icon URL

    const style = {
        borderRadius: needDifferentBorderRadius ? borderRadiusCustom : borderRadius,
        width: width,
        height: height,
    };

    return (
        <div className="overflow-hidden" style={style}>
            {isLoading && (
                <img
                    src={placeholderImage}
                    alt="Loading"
                    className="object-fill w-full h-full"
                    style={{ ...style }}
                />
            )}
            <img
                src={imageUrl}
                alt="Loaded"
                className={`object-fill w-full h-full ${isLoading ? 'hidden' : 'block'}`}
                onLoad={() => setIsLoading(false)}
                onError={(e) => {
                    e.target.onerror = null; // Prevents looping
                    e.target.src = errorImage; // Fallback image on error
                }}
                style={{ display: isLoading ? 'none' : 'block' }}
            />
        </div>
    );
};

export default CustomLoadingRectangularImage;
