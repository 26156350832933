export const partnerForm = "https://forms.gle/Mrk8dhUV3XXtWrD4A";
const isProd = true;
// APIs
// Government API for pin code
const pinCodeApi = "https://api.postalpincode.in/pincode/";
const razorPayOrderGeneratorApi = "https://api.razorpay.com/v1/orders/";
// Example: https://api.postalpincode.in/pincode/285001
const geocodeApi = "https://geocode.maps.co/reverse?";
// Example: "https://geocode.maps.co/reverse?lat=latitude&lon=longitude";
const geocodeApiKey = "&api_key=671b207094ba4440417257jcic5acd7";

// Private APIs for app usage
const masterUrl = isProd
    ? "https://prod.rashailagro.in"
    // : "https://preprod.dashboard.rashailagro.in";
    : "https://6fqm9v96-8000.inc1.devtunnels.ms";
const loginApi = `${masterUrl}/bazzar/login-bazzar/`;
const signupApi = `${masterUrl}/bazzar/signup-bazzar/`;
const verifyApi = `${masterUrl}/bazzar/verify-bazzar/`;
const productListFilterApi = `${masterUrl}/bazzar/products-list-filter`;
const todayOfferApi = `${masterUrl}/bazzar/todays-offer`;
const productListSearchApi = `${masterUrl}/bazzar/products-list`;
const categoriesListApi = `${masterUrl}/bazzar/category-list`;
const catSubcatViewApi = `${masterUrl}/bazzar/cat-subcat-view`;
const domainGetApi = `${masterUrl}/bazzar/domain-hierarchy`;
const searchSuggessationApi = `${masterUrl}/bazzar/search-suggection?search=`;
const shopAdv = `${masterUrl}/bazzar/shop-add`;
// for getting adv. = `${masterUrl}/bazzar/shop-add?is_advertisement=True`;
const trendingSearchApi = `${masterUrl}/bazzar/treanding-search`;
const popularProductsApi = `${masterUrl}/bazzar/popular-products`;
const productsListSearchApi = `${masterUrl}/bazzar/products-list-filter?`;
const productsDetailsApi = `${masterUrl}/bazzar/product-details/`;
// Example: `${masterUrl}/bazzar/product-details/<slug>/`;
const updateCartDataApi = `${masterUrl}/bazzar/products-by-slug/`;
const bazzarCreateOrderApi = `${masterUrl}/bazzar/bazzar-create-order/`;
const bazzarVerifyPaymentApi = `${masterUrl}/bazzar/bazzar-verify-payment/`;
const bazzarShippingAddressApi = `${masterUrl}/bazzar/shipping-address/`;
const bazzarOrderHistoryApi = `${masterUrl}/bazzar/order-history?user_id=`;
const bazzarShopsApi = `${masterUrl}/bazzar/shops`;
const bazzarShopsListApi = `${masterUrl}/bazzar/shop-list`;
const bazzarManufacturersApi = `${masterUrl}/bazzar/manufacturers`;
const bazzarManufacturersGetByNameApi = `${masterUrl}/bazzar/manufacturer/get-by-name/`;
// Example = `${masterUrl}/bazzar/manufacturer/get-by-name/<slug>/`;
const bazzarUserShippingAddressApi = `${masterUrl}/bazzar/user-shipping-address`;
const bazzarCategoriesWithTopProductsApi = `${masterUrl}/bazzar/categories-with-top-products`;
const bazzarShopEnquiryFormApi = `${masterUrl}/bazzar/shop-enquiry/`;

export {
    isProd,
    masterUrl,
    loginApi,
    signupApi,
    verifyApi,
    productListFilterApi as productListApi,
    productListSearchApi,
    categoriesListApi,
    searchSuggessationApi,
    shopAdv,
    trendingSearchApi,
    popularProductsApi,
    productsListSearchApi,
    productsDetailsApi,
    updateCartDataApi,
    bazzarCreateOrderApi,
    bazzarVerifyPaymentApi,
    bazzarShippingAddressApi,
    bazzarOrderHistoryApi,
    pinCodeApi,
    razorPayOrderGeneratorApi,
    catSubcatViewApi,
    bazzarShopsApi,
    bazzarManufacturersApi,
    bazzarUserShippingAddressApi,
    bazzarCategoriesWithTopProductsApi,
    domainGetApi,
    geocodeApi,
    geocodeApiKey,
    todayOfferApi,
    bazzarManufacturersGetByNameApi,
    bazzarShopsListApi,
    bazzarShopEnquiryFormApi,
};