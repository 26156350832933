import React, { useState, useEffect } from 'react';
import CustomLoadingRectangularImage from './RectangularImage';

// Modal Component
const Modal = ({ children, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg overflow-auto max-w-4xl w-full p-4 relative">
                {/* Close Button */}
                <button
                    className="absolute top-2 right-2 text-red-800 rounded-full p-2 text-3xl"
                    onClick={onClose}
                >
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};

// Gallery Component
const GalleryComponent = ({ images = [] }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openModal = (index) => {
        setCurrentIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowRight') {
            handleNext();
        } else if (e.key === 'ArrowLeft') {
            handlePrev();
        } else if (e.key === 'Escape') {
            closeModal();
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full">
            {images.slice(0, 6).map((img, index) => (
                <div
                    key={index}
                    className="relative transition-transform duration-300 ease-in-out transform hover:scale-105 max-h-96"
                >
                    <CustomLoadingRectangularImage
                        imageUrl={img.image}
                        borderRadius={4}
                        needFitCover={false}
                        height={120}
                    />
                </div>
            ))}

            {/* Display remaining images count if there are more than 6 images */}
            {images.length > 6 && (
                <div
                    className="relative transition-transform duration-300 ease-in-out transform hover:scale-105 max-h-96"
                    onClick={() => openModal(6)} // You might want to open the modal from the 7th image
                >
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center cursor-pointer">
                        <span className="text-white text-lg font-semibold">+{images.length - 6} more</span>
                    </div>
                </div>
            )}

            {isModalOpen && (
                <Modal onClose={closeModal}>
                    <div className="flex flex-col items-center">
                        {/* Large Image Display */}
                        <div className="relative mb-4 flex flex-row items-center">
                            <button
                                onClick={handlePrev}
                                className="absolute left-0 z-10 p-2 text-white bg-black bg-opacity-50 rounded-full"
                            >
                                &lt;
                            </button>
                            <div className="flex justify-center item-center max-h-96">
                                <img
                                    src={images[currentIndex].image}
                                    alt={`Image ${currentIndex + 1}`}
                                    className="w-full h-auto max-w-2xl object-cover"
                                />
                            </div>
                            <button
                                onClick={handleNext}
                                className="absolute right-0 z-10 p-2 text-white bg-black bg-opacity-50 rounded-full"
                            >
                                &gt;
                            </button>
                        </div>

                        {/* Thumbnail Gallery */}
                        <div className="flex space-x-2 overflow-x-auto mt-4">
                            {images.map((img, idx) => (
                                <img
                                    key={idx}
                                    src={img.image}
                                    alt={`Thumbnail ${idx + 1}`}
                                    className={`w-24 h-24 object-cover cursor-pointer transition-transform duration-200 transform ${currentIndex === idx ? 'scale-110 border-2 border-blue-500' : ''}`}
                                    onClick={() => handleThumbnailClick(idx)}
                                />
                            ))}
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default GalleryComponent;
